import $ from "jquery";
import { logFactory, isCompExists, components } from "GlobalSite";

const compName = "comp_aem_hp-info-block";
const Log = logFactory(compName);
const { log, error } = Log;
const {
  compTechUtilTooltipmanager: { TooltipManager },
} = components;

log("--->");

$(function domReady() {
  if (!isCompExists(compName, Log)) {
    return;
  }

  new TooltipManager({
    tooltipCtaSelector: ".tooltip-cta",
    tooltipSelector: ".tooltip",
    arrowSelector: ".arrow",
    $tooltipsContainer: $(".hp-info-block"),
  });
});
